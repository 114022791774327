import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const resolveConfiguration = () => {
  return config;
};

const defaultApp = app.initializeApp(resolveConfiguration());

/**
 * @type {app.database.Database}
 */
const db = defaultApp && defaultApp.database ? defaultApp.database() : {};

/**
 * @type {firebase.firestore.Firestore}
 */
const firestore = defaultApp && defaultApp.firestore ? defaultApp.firestore() : {};

/**
 * @type {app.auth.Auth}
 */
const auth = app && app.auth ? app.auth() : {};

/**
 * @type {app.storage.Storage}
 */
const storage = app && app.storage ? app.storage() : {};

/**
 * @type {app.functions.Functions}
 */
const functions = app && app.functions ? app.functions() : {};

const provider = new app.auth.GoogleAuthProvider();

if (config.projectId.startsWith("demo-")) {
  console.warn("Demo project detected. Connecting to emulator services.");
  auth.useEmulator("http://127.0.0.1:9099");
  db.useEmulator("127.0.0.1", 9000);
  firestore.useEmulator("127.0.0.1", 8080);
  functions.useEmulator("127.0.0.1", 5001);
}

const Firebase = {
  db,
  firestore,
  auth,
  storage,
  functions,
  defaultApp,
  app,
  provider,
};
export default Firebase;
