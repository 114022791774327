import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";

import { interactAppPrompt, setAppPromptHeight } from "../../actions/global.actions";
import { trackGetAppClicked } from "../../actions/metrics.actions";
import { StaticStrings } from "../../constants/static-string-constants";
import AppConfig from "../../config";

const useAppPrompt = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const isShown = useSelector((state) => state.global.appPrompt.isShown);
  const isExpanded = useSelector((state) => state.global.appPrompt.isExpanded);
  const height = useSelector((state) => state.global.appPrompt.height);

  const appUrl = isIOS ? StaticStrings.iOSStoreUrl : StaticStrings.androidStoreUrl;

  const currentUrl = AppConfig.appSchema + pathname.substring(1) + encodeURIComponent(search);

  return {
    appUrl,
    currentUrl,
    isShown,
    isExpanded,
    height,
    interactAppPrompt: useCallback(() => dispatch(interactAppPrompt(!isExpanded)), [dispatch, isExpanded]),
    setAppPromptHeight: useCallback((height) => dispatch(setAppPromptHeight(height)), [dispatch]),
    onOpenApp: trackGetAppClicked,
  };
};

export default useAppPrompt;
