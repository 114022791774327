const config = {
  imgixUrl: "https://figure1.imgix.net/",
  versionBaseUrl: "https://s3.amazonaws.com/figure1-version-url/",
  appSchema: "figure1pro://",
  mixpanelToken: "1e52d084ba64e6a4d348997bfdb664a4",
  mixpanelUrl: "https://tracking-proxy-prod.web.app/tracking",
  uxcamToken: "5x4b1cqjv501akh",
  iterableAPIKey: "8050e8847cb54d4fbaff0532c20e86b3",
  gtm_token: "GTM-WT3JTC7",
  gtm_auth: "wvYA-_m9fMkXzGDW2phMtg",
  gtm_preview: "env-1",
  ipinfo: "5db3d1e380ae71",
};

export default config;
