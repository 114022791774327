import React, { useState, useEffect } from "react";
import { isEmpty, orderBy } from "lodash";
import { FilePond, registerPlugin } from "react-filepond";

import { create } from "../../../lib/react-doka/lib/doka.esm.min";
import i18n from "../../utils/i18n";
import { loadModels, getFullFaceDescription } from "../../face-api/face";
// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import "filepond/dist/filepond.min.css";
import "../../../lib/react-doka/lib/doka.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./CaseImageEditor.scss";

import warmUpImage from "../../face-api/warmup.png";
import cameraIcon from "../../assets/images/camera icon.svg";
import checkFlag, { FLAG_POSTS } from "../../features";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  FilePondPluginFileMetadata,
  FilePondPluginImageTransform,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginFileValidateType
);

// Our app
function CaseImageEditor({ fileRef, media, onRemoveFile, onFileEdit }) {
  /** ********************************** CONFIG ***************************************/
  const postsFlag = checkFlag(FLAG_POSTS);
  const postsClassName = postsFlag ? "filePost-v2-style" : "";
  const postsPorops = postsFlag
    ? {
        maxFiles: 5,
        imagePreviewHeight: "104px",
        labelIdle: `<img src="${cameraIcon}" height={40} width={40} alt="" />
        <span className="ml-2 text-14">
          ${i18n.t("shareCase.dragAndDropImages")} 
          <span class="filepond--label-action text-cool-blue">
            ${i18n.t("shareCase.browse")}
          </span>
        </span>`
      }
    : {
        labelIdle: `${i18n.t(
          "shareCase.dragAndDropImages"
        )} <span class="filepond--label-action">${i18n.t(
          "shareCase.browse"
        )}</span>`
      };

  const [loading, setLoading] = useState(true);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    loadModels().then(() => {
      warmUpFaceDetection();
    });
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    const existingFiles = fileRef.current.getFiles();
    let files = (media || []).filter((m) => {
      const alreadyIn = existingFiles.find(
        (fi) => fi.filename === m.filename || fi.filename === m.originalFilename
      );
      return !alreadyIn && (m.url || m.file);
    });

    if (!isEmpty(files)) {
      // fileRef.current.removeFiles();

      orderBy(files, "index", "desc").forEach((file) => {
        fileRef.current.addFile(file.file || file.url).then(
          (editorFile) => {
            editorFile.setMetadata({
              uploaded: true,
              serverIndex: file.index
            });
          },
          (err) => {}
        );
      });
    }
  }, [loading, fileRef, media]);

  /** ********************************* FUNCTIONS *************************************/

  const warmUpFaceDetection = () => {
    var img = new Image(); // Create new img element
    img.src = warmUpImage; // Set source path
    getFullFaceDescription(img).then(() => {
      setLoading(false);
    });
  };

  const onAddFile = async (err, f) => {
    const img = new Image();

    if (f.file) {
      img.src = URL.createObjectURL(f.file);
    } else if (f.url) {
      img.src = f.url;
    }

    async function loaded() {
      const width = img.width ? Math.floor(img.width / 32) : undefined;

      await processImg(img, width);
    }

    if (img.complete) {
      loaded();
    } else {
      img.addEventListener("load", loaded);
    }

    async function processImg(i) {
      return getFullFaceDescription(i).then((detections) => {
        if (detections.length > 0) {
          const markup = [];

          detections.forEach((d, index) => {
            const rectangle = {
              top: `${d.alignedRect.box.top.toFixed(0)}px`,
              left: `${d.alignedRect.box.left.toFixed(0)}px`,
              height: `${d.alignedRect.box.height.toFixed(0)}px`,
              width: `${d.alignedRect.box.width.toFixed(0)}px`,
              backgroundColor: "black"
            };
            markup.push(["rect", rectangle]);
          });

          f.setMetadata({
            markup: markup
          });
        }
      });
    }
  };

  /** ********************************** RENDER ***************************************/
  return (
    <div className={postsClassName}>
      <FilePond
        ref={fileRef}
        disabled={loading}
        allowMultiple={true}
        allowReorder={true}
        credits={false}
        acceptedFileTypes={["image/*"]}
        name="files"
        onremovefile={onRemoveFile}
        onaddfile={onAddFile}
        imageEditEditor={create({
          utils: ["crop", "color", "markup"],
          cropAspectRatioOptions: [
            {
              label: "Free",
              value: null
            },
            {
              label: "Portrait",
              value: 1.25
            },
            {
              label: "Square",
              value: 1
            },
            {
              label: "Landscape",
              value: 0.75
            }
          ],
          onconfirm: onFileEdit
        })}
        {...postsPorops}
      />
      {loading && (
        <div className="text-12 text-battleship-gray text-center pt-3">
          {i18n.t("shareCase.loadingImagePlugins")}
        </div>
      )}
    </div>
  );
}

export default CaseImageEditor;
