import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Figure1Layout from "../../@layouts/Figure1Layout";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import AppPrompt from "../../components/app-prompt/AppPrompt";
import F1FullScreenOptionsCard from "../../components/common/F1FullScreenOptionsCard";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import * as ROUTES from "../../constants/routes";
import createCase from "../../assets/images/create_clinical_case.svg";
import createPost from "../../assets/images/create_post.svg";
import checkFlag, { FLAG_POSTS } from "../../features";
import { trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";

const CreateNewCasePostOptions = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const postsFlag = checkFlag(FLAG_POSTS);
  /** ********************************** HOOKS ***************************************/
  useEffect(() => {
    trackPageEntry(
      METRICS_EVENT_NAME.SCREEN.UPLOAD.UPLOAD_SELECT_CLASSIFICATION_ENTRY
    );
  }, []);

  useEffect(() => {
    if (!postsFlag) {
      history.push(ROUTES.CASE_POSTING_NEW);
    }
  }, []);
  /** ********************************** FUNCTIONS ***************************************/
  const goToCreateNewCase = () => {
    history.push(getUrl(ROUTES.CASE_POSTING_NEW));
  };
  const goToCreateNewPost = () => {
    history.push(getUrl(ROUTES.POST_NEW));
  };
  /** ********************************** RENDER ***************************************/
  return (
    <Figure1Layout footer={<AppPrompt />}>
      <Figure1Page3Col
        mainContent={
          <div className="mt-4">
            <h1 className="helv-bold text-center text-22 line-height-28 mb-3">
              {i18n.t("createNewCasePostOptions.title")}
            </h1>
            <div className="mt-4">
              <F1FullScreenOptionsCard
                iconImg={createCase}
                circleIcon={true}
                title={i18n.t("createNewCasePostOptions.clinicalCase.title")}
                showChevron={false}
                className="mb-4"
                onOptionClick={goToCreateNewCase}
              />
              <F1FullScreenOptionsCard
                iconImg={createPost}
                circleIcon={true}
                title={i18n.t("createNewCasePostOptions.post.title")}
                badgeText={i18n.t("common.new")}
                description={i18n.t(
                  "createNewCasePostOptions.post.description"
                )}
                descriptionMuted={true}
                showChevron={false}
                onOptionClick={goToCreateNewPost}
              />
            </div>
          </div>
        }
      />
    </Figure1Layout>
  );
};

export default CreateNewCasePostOptions;
