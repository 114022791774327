import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Redirect as RRRedirect } from "react-router-dom";
import qs from "query-string";

// Originally lifted from https://github.com/remix-run/react-router/issues/5818#issuecomment-354581992

const mergeQueryStrings = (...args) => {
  const queryString = qs.stringify(
    _.transform(
      args,
      (result, value) => {
        _.assign(result, _.isString(value) ? qs.parse(value) : value);
      },
      {}
    )
  );
  return queryString ? "?" + queryString : "";
};

const QueryPreserveRedirect = ({ preserveQueryString, ...props }) => {
  const searchLoc = window?.location?.search;

  if (_.isUndefined(preserveQueryString)) {
    preserveQueryString = _.has(props, "from");
  }
  if (!preserveQueryString || !searchLoc) {
    return <RRRedirect {...props} />;
  }
  const { to, ...rest } = props;
  const toSearch = _.isString(to) ? qs.extract(to) : _.get(to, "search", "");
  const search = mergeQueryStrings(searchLoc, toSearch);
  const nextLocation = _.isString(to) ? { pathname: to.split("?")[0], search } : { ...to, search };
  return <RRRedirect to={nextLocation} {...rest} />;
};

QueryPreserveRedirect.propTypes = {
  preserveQueryString: PropTypes.bool,
};

export default QueryPreserveRedirect;
