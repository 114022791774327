/** @format */
import AppConfig from "../config";
import { isRequestSuccess } from "../utils/general-utils";

const BASE_URL = "https://ipinfo.io?token=";

const checkIpCountry = async () => {
  try {
    const url = `${BASE_URL}${AppConfig.ipinfo}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (isRequestSuccess(response)) {
      return response?.json();
    } else {
      throw new Error(`Failed to check country with error: ${response.statusText}`);
    }
  } catch (e) {
    console.log("failed to get ipstack info", e);
    throw e;
  }
};

export { checkIpCountry };
