import React from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import { useLocation } from "react-router-dom";
import RegistrationFormV2 from "./components/RegistrationForm.v2";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import i18n from "../../utils/i18n";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";
import useRedirectLinkHook from "../../hooks/useRedirectLinkHook";

const CreateRegistrationPageV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const loc = useLocation();

  const urlParams = new URLSearchParams(loc.search);
  const email = urlParams.get("email");

  useRedirectLinkHook();
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <>
            <div className="mt-0 mt-lg-5 shadow">
              <RegistrationFormV2 email={email} />
            </div>
            <p className="px-5 px-lg-0 text-15 color-battleship-gray text-center">
              By signing up, you agree to our
              {" "}
              <ExternalTextLink
                className="text-cool-blue cursor-pointer"
                url={StaticStrings.termsOfServiceUrl}
                linkText="terms &amp; conditions"
              />
              {" and "}
              <ExternalTextLink
                className="text-cool-blue cursor-pointer"
                url="https://formedics.com/privacy-policy/"
                linkText="privacy policy"
              />
              .
            </p>
          </>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default CreateRegistrationPageV2;
